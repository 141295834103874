<template>
  <b-modal
    id="add-new-ingredient-sidebar"
    centered
    :visible="isAddNewIngredientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    :title="$t('ingredientAdd')"
    @ok="onSubmit(blankIngredientData)"
    @hidden="formValidation(resetblankingredient).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankingredient
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 d-flex"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <div class="w-50">
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- Kod -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('code')" label-for="kod">
              <b-form-input
                id="kod"
                v-model="blankIngredientData.kod"
                autofocus
                :state="
                  formValidation(resetblankingredient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ad -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="name"
          >
            <b-form-group :label="$t('name')" label-for="name">
              <b-form-input
                id="name"
                v-model="blankIngredientData.ingredientname"
                autofocus
                :state="
                  formValidation(resetblankingredient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Fiyat -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="fiyat"
          >
            <b-form-group :label="$t('price')" label-for="fiyat">
              <b-form-input
                id="fiyat"
                v-model="blankIngredientData.fiyat"
                autofocus
                type="number"
                step="any"
                :state="
                  formValidation(resetblankingredient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group :label="$t('type')" label-for="type">
            <v-select
              v-model="blankIngredientData.cins"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cins"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </div>
        <b-card-actions
          class="w-50 overflow-auto border rounded ml-1"
          style="height: 350px"
          :title="$t('values')"
          action-collapse
          :collapsed="false"
        >
          <!-- Values -->
          <div
            v-for="value in blankIngredientData.values"
            :key="value.id"
            rules="required"
            name="fiyat"
          >
            <b-form-group :label="value.nutrientname" label-for="fiyat">
              <BInputGroup>
                <b-form-input
                  :id="value.nutrientname"
                  v-model="value.deger"
                  step="any"
                  type="number"
                  trim
                  placeholder=""
                />
                <b-input-group-append is-text>
                  <span>{{ value.birim }}</span>
                </b-input-group-append>
              </BInputGroup>
            </b-form-group>
          </div>
        </b-card-actions>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewIngredientSidebarActive",
    event: "update:is-add-new-ingredient-sidebar-active",
  },
  props: {
    isAddNewIngredientSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankIngredientData: {},
      cins: [
        {
          label: this.$t("grain"),
          value: 1,
        },
        {
          label: this.$t("roughage"),
          value: 2,
        },
      ],
      formValidation: formValidation,
    };
  },
  mounted() {
    this.changed(false);
  },

  methods: {
    async fetchNewIngredient() {
      var { data } = await this.$http.get("/Ingredients/GetNewIngredient/", {
        headers: { culture: getUserData().cultureinfo, tip: "0", plantid: "0" },
      });
      this.blankIngredientData = data.resultdata[0];
      this.blankIngredientData.cins = 2;
    },
    async changed(val) {
      await this.fetchNewIngredient();
      this.$emit("update:is-add-new-ingredient-sidebar-active", val);
    },
    onSubmit(blankIngredientData) {
      var tinyCompanyIngredient = {
        values: [],
      };
      tinyCompanyIngredient.id = blankIngredientData.id;
      tinyCompanyIngredient.cins = blankIngredientData.cins;
      tinyCompanyIngredient.ingredientname = blankIngredientData.ingredientname;
      tinyCompanyIngredient.kod = blankIngredientData.kod;
      tinyCompanyIngredient.fiyat = parseFloat(blankIngredientData.fiyat);
      blankIngredientData.values.map((x) => {
        if (x.deger != 0) {
          tinyCompanyIngredient.values.push({
            id: x.id,
            deger: parseFloat(x.deger),
          });
        }
      });
      store
        .dispatch("companyProductsModule/addCompanyProduct", {
          newIngredient: tinyCompanyIngredient,
          tip: "",
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-ingredient-sidebar-active", false);
        });
    },
    async resetblankingredient() {
      this.blankIngredientData = await this.fetchNewIngredient;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-ingredient-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
