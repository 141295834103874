var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-ingredient-sidebar","centered":"","visible":_vm.isAddNewIngredientSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","size":"lg","backdrop":"","no-header":"","right":"","ok-title":_vm.$t('ok'),"ok-only":"","title":_vm.$t('ingredientAdd')},on:{"ok":function($event){return _vm.onSubmit(_vm.blankIngredientData)},"hidden":function($event){_vm.formValidation(_vm.resetblankingredient).resetForm},"change":function (val) { return _vm.changed(val); }}},[_c('validation-observer',{ref:"formValidation(\n    resetblankingredient\n  ).refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 d-flex",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('div',{staticClass:"w-50"},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('code'),"label-for":"kod"}},[_c('b-form-input',{attrs:{"id":"kod","autofocus":"","state":_vm.formValidation(_vm.resetblankingredient).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankIngredientData.kod),callback:function ($$v) {_vm.$set(_vm.blankIngredientData, "kod", $$v)},expression:"blankIngredientData.kod"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","autofocus":"","state":_vm.formValidation(_vm.resetblankingredient).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankIngredientData.ingredientname),callback:function ($$v) {_vm.$set(_vm.blankIngredientData, "ingredientname", $$v)},expression:"blankIngredientData.ingredientname"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"fiyat"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('price'),"label-for":"fiyat"}},[_c('b-form-input',{attrs:{"id":"fiyat","autofocus":"","type":"number","step":"any","state":_vm.formValidation(_vm.resetblankingredient).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankIngredientData.fiyat),callback:function ($$v) {_vm.$set(_vm.blankIngredientData, "fiyat", $$v)},expression:"blankIngredientData.fiyat"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t('type'),"label-for":"type"}},[_c('v-select',{staticClass:"w-100",attrs:{"transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.cins,"reduce":function (val) { return val.value; }},model:{value:(_vm.blankIngredientData.cins),callback:function ($$v) {_vm.$set(_vm.blankIngredientData, "cins", $$v)},expression:"blankIngredientData.cins"}})],1)],1),_c('b-card-actions',{staticClass:"w-50 overflow-auto border rounded ml-1",staticStyle:{"height":"350px"},attrs:{"title":_vm.$t('values'),"action-collapse":"","collapsed":false}},_vm._l((_vm.blankIngredientData.values),function(value){return _c('div',{key:value.id,attrs:{"rules":"required","name":"fiyat"}},[_c('b-form-group',{attrs:{"label":value.nutrientname,"label-for":"fiyat"}},[_c('BInputGroup',[_c('b-form-input',{attrs:{"id":value.nutrientname,"step":"any","type":"number","trim":"","placeholder":""},model:{value:(value.deger),callback:function ($$v) {_vm.$set(value, "deger", $$v)},expression:"value.deger"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('span',[_vm._v(_vm._s(value.birim))])])],1)],1)],1)}),0)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }