import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import router from "@/router";
export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refIngredientListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "kod", label: VueI18n.t("code"), sortable: true },
    {
      key: "ingredientname",
      label: VueI18n.t("ingredientName"),
      sortable: false,
    },
    { key: "cinsname", label: VueI18n.t("type"), sortable: false },
    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref(null);
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allIngredients = ref([]);
  const filteredIngredients = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredIngredients.value.length;
    const to = perPage.value * currentPage.value < localItemsCount;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: to
        ? perPage.value * (currentPage.value - 1) + perPage.value
        : localItemsCount,
      of: allIngredients.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value) {
      filteredIngredients.value = allIngredients.value.filter((x) =>
        x.ingredientname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    } else {
      filteredIngredients.value = allIngredients.value;
    }
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchIngredients = async (ctx, callback) => {
    var ingredient = await store
      .dispatch("plantsModule/fetchIngredients", router.currentRoute.params.id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("ingredients") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allIngredients.value = ingredient;
    filteredIngredients.value = [...ingredient];
    refetchData();
    return ingredient;
  };

  const deleteIngredient = (ctx) => {
    store
      .dispatch("plantsModule/deleteIngredient", ctx)
      .then((response) => {
        fetchIngredients();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("ingredients") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("ingredients") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchIngredients,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refIngredientListTable,
    deleteIngredient,
    allIngredients,
    filteredIngredients,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
